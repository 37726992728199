@font-face {
    font-family: 'UnicaOne';
    src: url('assets/fonts/UnicaOne-Regular.ttf');
}

@font-face {
    font-family: 'CrimsonText';
    src: url('assets/fonts/CrimsonText-Roman.ttf');
    font-weight: normal;
}

@font-face {
    font-family: 'CrimsonText';
    src: url('assets/fonts/CrimsonText-Bold.ttf');
    font-weight: bold;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
